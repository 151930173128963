import { isBoolean } from 'lodash';

const applicantContent = {
  name: 'Applicants',
  code: 3,
  key: 'isApplicants',
  children: [
    {
      name: 'Primary Applicant',
      activeContent: true,
      childs: [
        { name: 'Details', subCode: 1 },
        { name: 'Employment', subCode: 2 },
        { name: 'Assets and Liabilities', subCode: 3 }
      ]
    }
  ]
};

const gurantorsContent = {
  name: 'Guarantors',
  code: 4,
  key: 'isGurantors',
  children: [
    {
      name: 'Guarantor 1',
      activeContent: true,
      childs: [
        { name: 'Details', subCode: 1 },
        { name: 'Employment', subCode: 2 },
        { name: 'Assets and Liabilities', subCode: 3 }
      ]
    }
  ]
};

const opportunityContents = [
  { name: 'Property Information', code: 1, key: 'isPropertyInfo' },
  { name: 'Mortgage', code: 2, key: 'isMortgage' },
  { ...applicantContent },
  { ...gurantorsContent },
  { name: 'Documents', code: 6, key: 'isDocuments' },
  { name: 'Publish', code: 5, key: 'isPublish' }
];

const applicantChildren = {
  name: 'Primary Applicant',
  code: 3,
  key: 'isApplicants',
  childs: [
    { name: 'Details', subCode: 1 },
    { name: 'Employment', subCode: 2 },
    { name: 'Assets and Liabilities', subCode: 3 }
  ]
};

const gurantorChildren = {
  name: 'Guarantor 1',
  code: 4,
  key: 'isGurantors',
  childs: [
    { name: 'Details', subCode: 1 },
    { name: 'Employment', subCode: 2 },
    { name: 'Assets and Liabilities', subCode: 3 }
  ]
};

const setOpportunityId = (id) => {
  localStorage.setItem('opportunityId', id);
};

const getOpportunityId = (id) => {
  return localStorage.getItem('opportunityId');
};

const removeOpportunityId = () => localStorage.removeItem('opportunityId');

const setApplicantId = (id) => {
  localStorage.setItem('applicantGurantors', id);
};

const getApplicantId = () => {
  return localStorage.getItem('applicantGurantors');
};

const removeApplicantId = () => localStorage.removeItem('applicantGurantors');

const ASSET_KEY = 'asset';
const LIABILITY_KEY = 'liability';
const OTHER_INCOME_KEY = 'income';

const getSubItems = (code, subCode) => {
  switch (subCode) {
    case 1:
      return code === 3 ? 'Applicants > Details' : 'Guarantors > Details';
    case 2:
      return code === 3 ? 'Applicants > Employment' : 'Guarantors > Employment';
    case 3:
      return code === 3
        ? 'Applicants > Assets and liabilities'
        : 'Guarantors > Assets and liabilities';
    default:
      break;
  }
};

const getActiveContent = (open, code, subCode) => {
  if (open) {
    return 'Contents';
  }
  switch (code) {
    case 0:
      return 'Import from Filogix';
    case 1:
      return 'Property Information';
    case 2:
      return 'Mortgage';
    case 3:
      if (subCode === 0) {
        return 'Applicants';
      } else {
        return getSubItems(3, subCode);
      }

    case 4:
      if (subCode === 0) {
        return 'Gurantors';
      } else {
        return getSubItems(4, subCode);
      }

    case 5:
      return 'Publish';
    default:
      break;
  }
};

const checkForApplicantsValues = (data) => {
  const { isAssetsLiabilitiesAdded, isEmploymentAdded } = data || {};

  if (!isAssetsLiabilitiesAdded || !isEmploymentAdded) {
    return true;
  }

  for (const key in data) {
    if (Array.isArray(data[key])) {
      if (data[key].some(Array.isArray)) {
        if (data[key].some((arr) => arr.length > 0)) {
          return true;
        }
      } else if (data[key].length > 0) {
        return true;
      }
    }
  }

  return false;
};

const hasNonEmptyKeysApplicants = (data) => {
  return data.some((applicant) => {
    // Check if applicantDetailsKeys is not empty
    if (applicant.applicantDetailsKeys.length > 0) return true;

    // Check if any subarray in incomeKeys is not empty
    if (applicant.incomeKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if assetsKeys is not empty
    if (applicant.assetsKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if liabilitiesKeys is not empty
    if (applicant.liabilitiesKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if any subarray in currentEmploymentKeys is not empty
    if (applicant.currentEmploymentKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if any subarray in previousEmploymentKeys is not empty
    if (applicant.previousEmploymentKeys.some((subArray) => subArray.length > 0)) return true;

    // If none of the keys are non-empty, return false for this applicant
    return false;
  });
};

const isArrayEmpty = (arr) => {
  if (!Array.isArray(arr)) return false;
  return (
    (arr && arr.length === 0) ||
    arr.every((item) => (Array.isArray(item) ? isArrayEmpty(item) : false))
  );
};

const isAllArraysEmpty = (obj, type, applicantData) => {
  // Helper function to check if an array is empty or contains only empty arrays

  if (type === 'applicant') {
    let otherIncomeCondition = isArrayEmpty(obj.incomeKeys);
    let assetsCondition = isArrayEmpty(obj.assetsKeys);
    let liabilitiesCondition = isArrayEmpty(obj.liabilitiesKeys);
    let currentEmpCondition = isArrayEmpty(obj.currentEmploymentKeys);
    let preEmpCondition = isArrayEmpty(obj.previousEmploymentKeys);

    if (applicantData) {
      if (!applicantData.isCurrentlyEmployed) {
        applicantData.isPreviouslyEmployed = false;
        currentEmpCondition = true;
      }

      if (!applicantData.isPreviouslyEmployed) {
        preEmpCondition = true;
      }

      if (!applicantData.isOtherSourceIncome) {
        otherIncomeCondition = true;
      }

      if (!applicantData.isHavingAssets) {
        assetsCondition = true;
      }

      if (!applicantData.isHavingLiabilities) {
        liabilitiesCondition = true;
      }
    }

    return !(
      otherIncomeCondition &&
      assetsCondition &&
      liabilitiesCondition &&
      currentEmpCondition &&
      preEmpCondition
    );
  }
  // Check all relevant keys
  return !(
    isArrayEmpty(obj.applicantDetailsKeys) &&
    isArrayEmpty(obj.incomeKeys) &&
    isArrayEmpty(obj.assetsKeys) &&
    isArrayEmpty(obj.liabilitiesKeys) &&
    isArrayEmpty(obj.currentEmploymentKeys) &&
    isArrayEmpty(obj.previousEmploymentKeys)
  );
};

const applicantErrorCard = (obj, applicantData) => {
  let otherIncomeCondition = isArrayEmpty(obj.incomeKeys);
  let assetsCondition = isArrayEmpty(obj.assetsKeys);
  let liabilitiesCondition = isArrayEmpty(obj.liabilitiesKeys);
  let currentEmpCondition = isArrayEmpty(obj.currentEmploymentKeys);
  let preEmpCondition = isArrayEmpty(obj.previousEmploymentKeys);

  if (applicantData) {
    if (!applicantData.isPreviouslyEmployed) {
      preEmpCondition = true;
    }

    if (!applicantData.isCurrentlyEmployed) {
      applicantData.isPreviouslyEmployed = false;
      currentEmpCondition = true;
    }

    if (!applicantData.isOtherSourceIncome) {
      otherIncomeCondition = true;
    }

    if (!applicantData.isHavingAssets) {
      assetsCondition = true;
    }

    if (!applicantData.isHavingLiabilities) {
      liabilitiesCondition = true;
    }
  }

  if (!currentEmpCondition) {
    return 'current-employment-card';
  }

  if (!preEmpCondition) {
    return 'previous-employment-card';
  }

  if (!otherIncomeCondition) {
    return 'other-income-card';
  }

  if (!assetsCondition) {
    return 'assets-card';
  }

  if (!liabilitiesCondition) {
    return 'liabilities-card';
  }

  return null;
};

const isCardFieldsCompleted = (incompleteFields, applicantId, cardType, cardStatus) => {
  if (isBoolean(cardStatus) && !cardStatus) {
    return false;
  }

  const { missingApplicantGurantorFields } = incompleteFields;

  if (missingApplicantGurantorFields && missingApplicantGurantorFields.length) {
    const index = missingApplicantGurantorFields.findIndex((val) => {
      return val.id === applicantId;
    });
    if (index > -1) {
      const previousEmpFields = missingApplicantGurantorFields[index][cardType];
      const checkFields = previousEmpFields.some((innerArray) => innerArray.length > 0);
      return checkFields;
    }
  }
};

const formatMonthAndYear = (monthAndYear) => {
  const [month, year] = monthAndYear.split('-'); // Split into month and year
  const date = new Date(`${year}-${month}-01`); // Create a Date object
  const monthName = date.toLocaleString('en-US', { month: 'short' }); // Get the abbreviated month name
  return `${monthName} ${year}`; // Format as "Dec 2024"
};

const formatTypeName = (type) => {
  // Replace underscores with spaces
  let formattedType = type.replace(/_/g, ' ');

  // Add a space between numeric years and the trailing characters (e.g., "2022T4" -> "2022 T4")
  formattedType = formattedType.replace(/(\d+)([a-zA-Z])/g, '$1 $2');

  // Add spaces between words if camelCase is used (e.g., "EmploymentLetter" -> "Employment Letter")
  formattedType = formattedType.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  formattedType = formattedType
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedType;
};

const documentsAllArraysEmpty = (obj) => {
  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        // If the array contains objects, check if they have non-empty "missingDocuments"
        if (typeof value[0] === 'object' && value[0] !== null) {
          for (const item of value) {
            if (item.missingDocuments && item.missingDocuments.length > 0) {
              return false; // A nested array is not empty
            }
          }
        } else {
          return false; // A simple array is not empty
        }
      }
    } else if (typeof value === 'object' && value !== null) {
      // Recursively check nested objects
      if (!areAllArraysEmpty(value)) {
        return false;
      }
    }
  }
  return true; // All arrays are empty
};

const validateDoctype = (
  docType,
  requestedData,
  documentDetails,
  additionalDocuments,
  isChat,
  setAdditionalDocuments,
  index
) => {
  if (docType) {
    if (docType.length > 120) {
      setAdditionalDocuments((prev) => {
        prev[index] = { ...prev[index], isNotValid: true };
        return prev;
      });
      throw Error('The document name must be 120 characters or fewer.');
    }
    const resultString = docType?.trim().toLowerCase().replace(/\s+/g, '');
    const lowerCaseString = docType?.trim().toLowerCase();
    const findKeyWithDocType = (data) => {
      for (const key in data) {
        const items = data[key];
        if (Array.isArray(items)) {
          for (const item of items) {
            const itemName = item?.name?.toLowerCase();
            const itemType = item?.type?.toLowerCase();
            const itemTitle = item?.titleName?.toLowerCase();

            if (
              itemName === docType.trim().toLowerCase() ||
              itemType === docType.trim().toLowerCase() ||
              itemTitle === docType.trim().toLowerCase() ||
              item?.list?.some(
                (subItem) =>
                  subItem?.name?.toLowerCase() === docType.trim().toLowerCase() ||
                  subItem?.type?.toLowerCase() === docType.trim().toLowerCase() ||
                  subItem?.titleName?.toLowerCase() === docType.trim().toLowerCase()
              )
            ) {
              return key;
            }
          }
        }
      }
      return null;
    };

    const keyMappings = {
      subjectProperty: 'subjectPropertyDocs',
      mortgage: 'mortgageDocs',
      gurantors: 'guarantorDocs',
      applicants: 'applicantGuarantorDocs'
    };

    const checkDocumentExists = (docs, docKeyName) => {
      if (
        docKeyName === 'subjectPropertyDocs' ||
        docKeyName === 'mortgageDocs' ||
        docKeyName === 'additionalDocs' ||
        docKeyName === 'sharedPrivatelyDocs'
      ) {
        return docs?.find((doc) => {
          return (
            ([doc.type.toLowerCase(), doc.type.toLowerCase().replace(/\s+/g, '')].includes(
              lowerCaseString
            ) ||
              [doc.type.toLowerCase(), doc.type.toLowerCase().replace(/\s+/g, '')].includes(
                resultString
              )) &&
            doc.docs &&
            doc.docs.length
          );
        });
      } else {
        if (docs.length) {
          const updatedDocs = docs.flatMap((applicant) => applicant.data);
          return updatedDocs?.find(
            (doc) =>
              doc &&
              doc.docs &&
              doc.docs.length &&
              ([doc.type.toLowerCase(), doc.type.toLowerCase().replace(/\s+/g, '')].includes(
                lowerCaseString
              ) ||
                [doc.type.toLowerCase(), doc.type.toLowerCase().replace(/\s+/g, '')].includes(
                  resultString
                ))
          );
        }
      }
    };

    if (!isChat) {
      let keyName = findKeyWithDocType(requestedData);

      keyName = keyMappings[keyName] || keyName;

      if (keyName) {
        setAdditionalDocuments((prev) => {
          prev[index] = { ...prev[index], isNotValid: true };
          return prev;
        });
        if (documentDetails && checkDocumentExists(documentDetails[keyName], keyName)) {
          throw Error(
            'Document name is already in use, please try another or add a prefix/suffix.'
          );
        }

        throw Error(
          'Document name is already in use, but no files were uploaded during creation of this opportunity. To use the same document name, please edit the opportunity and upload the appropriate file.'
        );
      }

      if (
        documentDetails &&
        checkDocumentExists(documentDetails['additionalDocs'], 'additionalDocs')
      ) {
        setAdditionalDocuments((prev) => {
          prev[index] = { ...prev[index], isNotValid: true };
          return prev;
        });
        throw Error(
          'Document name is already in use, but no files were uploaded during creation of this opportunity. To use the same document name, please edit the opportunity and upload the appropriate file.'
        );
      }

      if (additionalDocuments.length) {
        const findDocumentType = additionalDocuments.filter(
          (doc) =>
            doc.type.toLowerCase() === lowerCaseString || doc.type.toLowerCase() === resultString
        );

        if (findDocumentType && findDocumentType.length > 1) {
          setAdditionalDocuments((prev) => {
            prev[index] = { ...prev[index], isNotValid: true };
            return prev;
          });
          throw Error(
            'Document name is already in use, but no files were uploaded during creation of this opportunity. To use the same document name, please edit the opportunity and upload the appropriate file.'
          );
        }
      }
    } else {
      const docKeys = [
        'subjectPropertyDocs',
        'mortgageDocs',
        'additionalDocs',
        'sharedPrivatelyDocs',
        'applicantGuarantorDocs',
        'guarantorDocs'
      ];

      if (docKeys.some((key) => checkDocumentExists(documentDetails[key], key))) {
        throw new Error(
          'Document name is already in use, please try another or add a prefix/suffix.'
        );
      }
    }

    return true;
  }
};

export {
  applicantContent,
  gurantorsContent,
  opportunityContents,
  setOpportunityId,
  getOpportunityId,
  setApplicantId,
  getApplicantId,
  applicantChildren,
  gurantorChildren,
  removeOpportunityId,
  removeApplicantId,
  ASSET_KEY,
  LIABILITY_KEY,
  OTHER_INCOME_KEY,
  getActiveContent,
  checkForApplicantsValues,
  hasNonEmptyKeysApplicants,
  isAllArraysEmpty,
  isCardFieldsCompleted,
  applicantErrorCard,
  isArrayEmpty,
  formatMonthAndYear,
  formatTypeName,
  documentsAllArraysEmpty,
  validateDoctype
};
