import React from 'react';
import { faSort, faSortAsc, faSortDesc, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isUpperCase,
  isLowerCase,
  isNumber,
  isSpecialCharacter,
  minEightCharacter,
  currencyFormat
} from 'utils';
import { BROKER_ROLE_KEY, INVESTOR_ROLE_KEY, MA_ROLE_KEY, PB_ROLE_KEY } from 'constant';
import Avatar from 'components/Avatar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge } from 'reactstrap';
import {
  ACCEPT_TYPE,
  DISCHARGE_KEY,
  FUNDED_BY_BROKER,
  FUNDED_TYPE,
  IN_PROGRESS
} from 'pages/Portfolio/Constant';
import { FUNDED } from 'pages/Opportunity/constant';
import { getLocationAddress } from 'pages/Opportunity/helper';

const columnHeaderTitle = (
  columnType,
  columnTitle,
  sortKey,
  sortValue,
  handleSortColumn,
  thClass = ''
) => {
  return (
    <div
      className={thClass}
      onClick={() => handleSortColumn(columnType, sortValue === 'asc' ? 'desc' : 'asc')}>
      <span>{columnTitle}</span>
      {sortKey === columnType && sortValue === 'asc' && (
        <FontAwesomeIcon icon={faSortAsc} className="text-muted sort-asc" />
      )}
      {sortKey === columnType && sortValue === 'desc' && (
        <FontAwesomeIcon icon={faSortDesc} className="text-muted sort-desc" />
      )}
      {sortKey !== columnType && (
        <>
          <FontAwesomeIcon icon={faSort} className="text-muted sort-ico-asc" />
        </>
      )}
    </div>
  );
};

const renderPasswordDescription = (values) => {
  const { password } = values;
  if (!password) {
    return (
      <p className="font-size-16 mt-2">
        <span>Use 8 or more characters with lowercase, uppercase, and special characters</span>
      </p>
    );
  } else {
    return (
      <div>
        <p className="mt-2">
          {minEightCharacter(password) ? (
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faXmark} className="text-danger" />
          )}{' '}
          8 characters
        </p>
        <p>
          {isUpperCase(password) ? (
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faXmark} className="text-danger" />
          )}{' '}
          Uppercase letter(s)
        </p>
        <p>
          {isLowerCase(password) ? (
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faXmark} className="text-danger" />
          )}{' '}
          Lowercase letter(s)
        </p>
        <p>
          {isNumber(password) ? (
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faXmark} className="text-danger" />
          )}{' '}
          Number(s)
        </p>
        <p>
          {isSpecialCharacter(password) ? (
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faXmark} className="text-danger" />
          )}{' '}
          Special character(s)
        </p>
      </div>
    );
  }
};

const oppLocation = (original, toggle = () => {}, callback) => {
  const getImageUrl = () => {
    return original.oppPropertyImage && !original.showPropertyImage
      ? original.oppPropertyImage
      : original.streetStaticImage;
  };

  return (
    <div
      className="d-flex align-items-center cursor-pointer position-relative"
      onClick={() => toggle(original)}>
      {callback ? callback(original) : null}
      {original.oppPropertyImage || original.streetStaticImage ? (
        <Avatar image={getImageUrl()} borderradius={4} size={60} />
      ) : (
        <Avatar
          fname={original.oppStreetAddress}
          lname={original.oppUnitNumber}
          fontsize={22}
          borderradius={4}
          size={50}
        />
      )}
      <div className="ms-3">
        {toggle ? (
          <span>{getLocationAddress(original)}</span>
        ) : (
          <Link
            to={`/opportunity-detail/${original.id}`}
            className="text-dark"
            state={{ status: 'inprogress' }}>
            {getLocationAddress(original)}
          </Link>
        )}

        <div className="text-muted font-size-14">{original.oppCity}</div>
      </div>
    </div>
  );
};

const oppAmount = (original, key, pathname) => {
  let updatedAmount = original.oppRequestedAmount;

  if (pathname === FUNDED) {
    updatedAmount = original.fundedAmount;
  } else if (original[key]) {
    updatedAmount = original[key];
  }

  return (
    <div className="d-flex align-items-center justify-content-end cursor-pointer">
      <div>
        <div>{currencyFormat(updatedAmount)}</div>
        <div className="text-muted font-size-14 text-end">
          {original.oppPosition ? moment.localeData().ordinal(original.oppPosition) : '-'}
        </div>
      </div>
    </div>
  );
};

const oppInterestRate = (original, key, pathname) => {
  let updatedInterestRate = original[key];

  if (pathname === FUNDED) {
    updatedInterestRate = original.fundedInterestRate;
  } else if (original.proposalInterestRate) {
    updatedInterestRate = original.proposalInterestRate;
  }

  return updatedInterestRate ? `${updatedInterestRate}%` : '-';
};

const oppLenderFee = (original, key, pathname) => {
  let updatedLenderFees = original[key];

  if (pathname === FUNDED) {
    updatedLenderFees = original.fundedLenderFees;
  } else if (original.proposalLenderFees) {
    updatedLenderFees = original.proposalLenderFees;
  }

  return updatedLenderFees ? `${currencyFormat(updatedLenderFees)}` : '-';
};

const oppTerm = (original, flag) => {
  return (
    <div className="d-flex align-items-center cursor-pointer ms-5">
      <div>
        <div>{original.term ? `${original.term} mo` : '-'}</div>
        <div className="text-muted font-size-14">
          {flag ? original.preferedTermType : original.oppPreferredTermType}
        </div>
      </div>
    </div>
  );
};

const oppStatusColumn = (original) => {
  return (
    <div className="table-status">
      {original.oppStatus === IN_PROGRESS && <Badge className="in-progress">In Progress</Badge>}
      {original.oppStatus === FUNDED_TYPE && <Badge className="funded">Funded</Badge>}
      {original.oppStatus === DISCHARGE_KEY && <Badge className="discharged">Discharged</Badge>}
      {original.oppStatus === ACCEPT_TYPE && (
        <div>
          <Badge className="accepted">Accepted</Badge>
        </div>
      )}
      {original.oppStatus === FUNDED_BY_BROKER && (
        <div>
          <Badge className="accepted">Accepted</Badge>
        </div>
      )}
    </div>
  );
};

const oppPublishedDate = (original) => {
  if (!original?.oppPublishedAt) return '-';

  const updatedAt = moment(original.oppPublishedAt); // Use moment directly
  const currentDate = moment(); // Get current date and time

  const dayDiff = currentDate.diff(updatedAt, 'days'); // Get difference in days

  let publishText;

  if (dayDiff === 0) {
    publishText = 'Today'; // If published today
  } else if (dayDiff > 0 && dayDiff < 5) {
    publishText = `${dayDiff} days ago`; // If within the last 4 days
  } else {
    publishText = updatedAt.format('ll'); // Fallback to full date format
  }

  return (
    <div className="d-flex align-items-center">
      <div>{publishText}</div>
    </div>
  );
};

const checkIfUserFillAllForm = (roleKey, userData) => {
  const {
    isEmailVerified,
    isPersonalInfo,
    isBrokerageInfo,
    isBusinessInfo,
    isLendingCreteria,
    isAccountVerified
  } = userData;
  switch (roleKey) {
    case BROKER_ROLE_KEY:
      return isEmailVerified && isPersonalInfo && isBrokerageInfo ? true : false;
    case PB_ROLE_KEY:
      return isEmailVerified && isPersonalInfo && isBrokerageInfo ? true : false;
    case INVESTOR_ROLE_KEY:
      return isEmailVerified && isPersonalInfo && isAccountVerified && isLendingCreteria
        ? true
        : false;
    case MA_ROLE_KEY:
      return isEmailVerified && isBusinessInfo && isLendingCreteria ? true : false;
    default:
      return false;
  }
};

const deleteDocandProfileImg = (data, initValues) => {
  const copyData = { ...data };
  const {
    identificationBackDocs,
    identificationDocs,
    secondaryIdentificationBackDocs,
    secondaryIdentificationDocs,
    profilePicture
  } = copyData;
  if (identificationBackDocs === initValues.identificationBackDocs) {
    delete copyData.identificationBackDocs;
  }
  if (identificationDocs === initValues.identificationDocs) {
    delete copyData.identificationDocs;
  }
  if (secondaryIdentificationBackDocs === initValues.secondaryIdentificationBackDocs) {
    delete copyData.secondaryIdentificationBackDocs;
  }
  if (secondaryIdentificationDocs === initValues.secondaryIdentificationDocs) {
    delete copyData.secondaryIdentificationDocs;
  }
  if (profilePicture === initValues.profilePicture) {
    delete copyData.profilePicture;
  }
  return copyData;
};

const convertToOrdinalString = (array) => {
  const resultArray = array.map((number, index) => {
    const suffix = (() => {
      if (number % 100 >= 11 && number % 100 <= 13) {
        return 'th';
      }
      switch (number % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    })();

    return number + suffix;
  });

  if (resultArray.length > 1) {
    const lastElement = resultArray.pop();
    resultArray.push('& ' + lastElement);
  }

  return resultArray.join(', ');
};

const checkForLenderFee = (user) => {
  const { workedWithMA, mortgageAdminId } = user;

  if (workedWithMA && mortgageAdminId) {
    return false;
  }

  return true;
};

const getLogoInitials = (accountName) => {
  if (!accountName) return '';

  const words = accountName.split(' ');
  let initials = '';

  words.forEach((word, index) => {
    if (word.length > 0 && index < 2) {
      initials += word.charAt(0);
    }
  });

  return initials;
};

const convertToTitleCase = (str) => {
  if (str === 'from-proceeds') {
    return 'From Proceeds';
  } else if (str === 'prior-to-advance') {
    return 'Prior to Advance';
  }
};

const trimAllFields = (values) => {
  const trimmedValues = {};

  // Iterate over each key in the values object
  Object.keys(values).forEach((key) => {
    // If the value is a string, trim it
    if (typeof values[key] === 'string') {
      trimmedValues[key] = values[key].trim();
    } else {
      // Otherwise, keep the original value
      trimmedValues[key] = values[key];
    }
  });

  return trimmedValues;
};

const getRandomColor = () => {
  const colorArray = [
    '#e89f58',
    '#c35353',
    '#d0b248',
    '#3b71da',
    '#60b752',
    '#75419d',
    '#37a5e1',
    '#54bc91',
    '#a06ca7',
    '#6c6fa7'
  ];
  const randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];

  return randomColor;
};

export {
  columnHeaderTitle,
  renderPasswordDescription,
  checkIfUserFillAllForm,
  oppLocation,
  oppAmount,
  oppInterestRate,
  oppLenderFee,
  oppTerm,
  oppPublishedDate,
  oppStatusColumn,
  deleteDocandProfileImg,
  convertToOrdinalString,
  checkForLenderFee,
  getLogoInitials,
  convertToTitleCase,
  trimAllFields,
  getRandomColor
};
